import React from 'react';

import { Button, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Done, GetApp } from '@material-ui/icons';

import { IBill } from 'types/bill';

const useStyles = makeStyles({
  actions: {
    display: 'inline-flex',
  },
  downloadButton: {
    marginLeft: '54px',
  },
  payButton: {
    maxWidth: '80px',
  },
});

interface IBillRowActionsProps {
  item: IBill;
}

export const BillRowActions: React.FC<IBillRowActionsProps> = ({ item }) => {
  const classes = useStyles();

  const onClickDownload = () => {
    const filename = item.report.name;

    const objectUrl: string = item.report.download_link;
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
    a.href = objectUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  };

  return (
    <div className={classes.actions}>
      {item.stage !== 'paid' ? (
        <Button
          className={classes.payButton}
          variant="contained"
          color="secondary"
          href={item.payment_link}
          target="_blank"
          disabled={parseFloat(item.amount_due) === 0}
        >
          Pay
        </Button>
      ) : (
        <Button className={classes.payButton} variant="contained" color="secondary" startIcon={<Done />} disabled>
          Paid
        </Button>
      )}
      {item.report.id ? (
        <IconButton className={classes.downloadButton} onClick={onClickDownload}>
          <GetApp />
        </IconButton>
      ) : null}
    </div>
  );
};
